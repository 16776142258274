import React, { useEffect } from "react";
import background from "../images/accent_slide_section.png";
import cmclogo from "../images/cmc-w-logo.png";
import Intro from "./Intro";
import Slideshow from "./Slideshow";
import {
  setTenant,
  setRedirectUri,
  setClientId,
  setState,
} from "../utils/SessionUtil";
import Notification from "./Notifications/Notification";
import { LanguageSelector } from "../translations/LanguagePicker";

const Layout = ({ children, state }) => {

  const getSearchParams = () => {
    return new URLSearchParams(window.location.search);
  };

  useEffect(() => {
    const searchParams = getSearchParams();
    if (searchParams.has("redirect_uri")) {
      setRedirectUri(searchParams.get("redirect_uri") as string);
    }
    if (searchParams.has("state")) {
      setState(searchParams.get("state") as string);
    }

    if (state.params.clientid) {
      setClientId(state.params.clientid);
    }
  }, []);

  return (
    <div className="global-wrapper font-sans">
      <div className="grid lg:grid-cols-11 sm:grid-cols-1 min-h-screen">
        <section className="col-span-7 relative min-h min-h-[40vh] bg-blue-700 lg:flex hidden items-center justify-center px-5 text-slate-900">
          <img src={cmclogo} alt="CMC" className="absolute top-16 left-16 z-10" />
          <img src={background} alt="" className="absolute left-0 bottom-0 z-0" />
          <Slideshow />
        </section>
        <main className="relative col-span-4 flex items-center bg-white justify-center px-5">
          <div className=" absolute top-10 right-10 z-10">
            <LanguageSelector />
          </div>
          <div className="max-w-md py-10">
            <Intro />
            {children}
          </div>
        </main>
      </div>
      <Notification />
    </div>
  );
}

export default Layout;
