export const setSession = (key: string, value: string) => {
  if (typeof localStorage === "undefined") {
    return undefined;
  }

  localStorage.setItem(key, value);
};

export const getSession = (key: string) => {
  if (typeof localStorage === "undefined") {
    return undefined;
  }

  return localStorage.getItem(key);
};

export const setClientId = (value: string) => {
  setSession("clientId", value);
};

export const setRedirectUri = (value: string) => {
  setSession("redirectUri", value);
};

export const setTenant = (value: string) => {
  setSession("tenant", value);
};

export const setState = (value: string) => {
  setSession("state", value);
};

export const getClientId = () => {
  return getSession("clientId");
};

export const getRedirectUri = () => {
  return getSession("redirectUri");
};

export const getTenant = () => {
  return getSession("tenant");
};

export const getState = () => {
  return getSession("state");
};
